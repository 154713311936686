import React from 'react'
import { useDropzone } from 'react-dropzone'
import './Dropzone.css'

interface Props {
  onDrop: (acceptedFiles: File[]) => void
}

const Dropzone: React.FC<Props> = (props: Props) => {
  const { onDrop } = props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      audio: ['audio/wav', 'audio/x-wav', 'audio/mpeg', 'audio/mp3'],
    },
  })
  const classNames = ['dropzone']
  if (isDragActive) {
    classNames.push('active')
  }
  return (
    <div {...getRootProps({ className: classNames.join(' ') })}>
      <input {...getInputProps()} />
      <p>Nutzen Sie Drag &amp; Drop oder klicken Sie hier um eine Audiodatei hochzuladen</p>
    </div>
  )
}

export default Dropzone
