import React from 'react'
import { Button } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt';
import SpeedIcon from '@mui/icons-material/Speed';
import ToolbarItem from './ToolbarItem'
import './Toolbar.css'

interface Props {
  canProcess: boolean
  onProcess: () => void
  silenceDuration: number
  setSilenceDuration: (value: number) => void
  speed: number
  setSpeed: (value: number) => void
  isProcessing: boolean
}

const Toolbar: React.FC<Props> = (props: Props) => {
  const {
    silenceDuration,
    setSilenceDuration,
    canProcess,
    onProcess,
    speed,
    setSpeed,
    isProcessing,
  } = props
  const classNames = ['toolbar']
  if (!canProcess) {
    classNames.push('disabled')
  }
  return (
    <div className={classNames.join(' ')}>
      <h2>Einstellungen</h2>
      <div className="tools">
        <ToolbarItem
          label="Stille zwischen Wörtern"
          value={silenceDuration}
          onChange={setSilenceDuration}
          min={0.01}
          max={0.5}
          step={0.01}
        />
        <ToolbarItem
          label="Geschwindigkeit"
          value={speed}
          onChange={setSpeed}
          min={0.1}
          max={4.0}
          step={0.1}
          formatLabel={(value) => `${value} x`}
          unitLabel="x"
          icon={<SpeedIcon />}
        />
      </div>
      <Button
        variant="contained"
        startIcon={<BoltIcon />}
        disabled={!canProcess || isProcessing}
        onClick={onProcess}
        style={{ marginTop: '1rem' }}
      >
        Verarbeitung starten
      </Button>
    </div>
  )
}

export default Toolbar
