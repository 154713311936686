import React, { useCallback, useState } from 'react';
import './App.css';
import Dropzone from './Dropzone';
import FilePreview from './FilePreview';
import Footer from './Footer';
import Toolbar from './Toolbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Result, { ResultInfo } from './Result';
import { Button, Stack, TextField } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { useLocalStorage } from 'react-use'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

interface Process {
  processing: boolean
  fileName: string
  result?: ResultInfo
  error?: string
}

export const zipResults = async (processes: Process[]): Promise<void> => {
  const Zip = await import('jszip')
  const saveAs = await import('file-saver')
  const zipFile = new Zip.default()
  processes.forEach((process, index) => {
    if (process.processing || !process.result) {
      return
    }
    const { fileName, result } = process
    const baseName = fileName.replace(/\.[^.]+$/, '')
    const { processedAudio } = result
    const processedAudioBase64 = processedAudio.replace(/^data:audio\/wav;base64,/, '')
    zipFile.file(`${baseName}_processed.wav`, processedAudioBase64, { base64: true })
  })
  const zipContent = await zipFile.generateAsync({ type: 'blob' })
  saveAs.default(zipContent, 'results.zip')
}

const App: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }, [setFiles])

  const [secret = '', setSecret] = useLocalStorage('secret', '')

  const [silenceDuration = 0.4, setSilenceDuration] = useLocalStorage('silenceDuration', 0.4);
  const [speed = 1.0, setSpeed] = useLocalStorage('speed', 1.0);

  const [processes, setProcesses] = useState<Process[]>([]);

  const isProcessing = processes.some((process) => process.processing);

  const onProcess = useCallback(() => {
    if (isProcessing) {
      return
    }
    const processes: Process[] = files.map((file) => ({
      processing: true,
      fileName: file.name,
    }));
    setProcesses(processes);

    files.forEach(async (file) => {
      try {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('secret', secret);
        formData.append('silenceDuration', silenceDuration.toString());
        formData.append('speed', speed.toString());
        const apiUrl = process.env.NODE_ENV === 'development' ? '/process' : `${window.location.href}process` // 'https://stt.aristech.cloud/sprachversteher/process'
        console.log({ apiUrl })
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: formData,
        })
        const result = await response.json();
        const p = processes.find((process) => process.fileName === file.name);
        if (p) {
          if (result.error) {
            p.processing = false;
            p.error = result.error;
            setProcesses([...processes].filter((process) => process.fileName !== file.name).concat(p));
            return
          }
          p.processing = false;
          p.result = result;
          setProcesses([...processes].filter((process) => process.fileName !== file.name).concat(p));
        }
      } catch (error) {
        console.error(error);
        const p = processes.find((process) => process.fileName === file.name);
        if (p) {
          p.processing = false;
          p.error = (error as any).message;
          setProcesses([...processes].filter((process) => process.fileName !== file.name).concat(p));
        }
      }
    });
  }, [files, setProcesses, isProcessing, silenceDuration, speed, secret])

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <header className="App-header">
          <h1>Sprachversteher Toolkit</h1>
          <TextField
            label="Schlüssel"
            placeholder="Schlüssel"
            type="password"
            size="small"
            value={secret}
            onChange={(event) => setSecret(event.target.value)}
          />
        </header>
        <div>
          {files.length < 1 && <Dropzone onDrop={onDrop} />}
          <FilePreview files={files} onUpdate={(files) => setFiles(files)} />
          <Toolbar
            canProcess={files.length > 0}
            isProcessing={isProcessing}
            onProcess={onProcess}
            silenceDuration={silenceDuration}
            setSilenceDuration={setSilenceDuration}
            speed={speed}
            setSpeed={setSpeed}
          />
          {processes.length > 0 && (
            <div className="results">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <h2 className="result-header">Ergebnisse</h2>
                <Button
                  onClick={() => zipResults(processes)}
                  startIcon={<CloudDownloadIcon />}
                >
                  Alle Downloaden
                </Button>
                <Button
                  onClick={() => setProcesses([])}
                  color="secondary"
                  startIcon={<DeleteSweepIcon />}
                >
                  Verwerfen
                </Button>
              </Stack>
              {processes.sort((a, b) => a.fileName > b.fileName ? 1 : -1).map((process) => (
                <Result
                  key={process.fileName}
                  processing={process.processing}
                  fileName={process.fileName}
                  result={process.result}
                  error={process.error}
                />
              ))}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
