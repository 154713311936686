import React from 'react'
import { LinearProgress, Box, Alert, Chip } from '@mui/material'

export interface SttInfos {
  /** The recognized utterance */
  utterance: string
  infos: Array<{
    word: string
    /** Start time in seconds */
    startTime: number
    /** End time in seconds */
    endTime: number
    /** Confidence in percent (0..1) */
    conf: number
  }>
}

export interface ResultInfo {
  sttInfos: SttInfos
  processedAudio: string
}

interface Props {
  processing: boolean
  fileName: string
  error?: string
  result?: ResultInfo
}

const Result: React.FC<Props> = (props: Props) => {
  const { processing, fileName, error, result } = props


  if (processing) {
    return (
      <Box sx={{ width: '100%' }}>
        <h3><Chip label={fileName} color="primary" /></h3>
        <LinearProgress />
      </Box>
    )
  }

  const baseName = fileName.replace(/\.[^.]+$/, '')
  return (
    <Box sx={{ width: '100%' }}>
      <h3><Chip label={fileName} color="primary" /></h3>
      {error && <Alert severity="error">{error}</Alert>}
      {result && (
        <>
          <p>{result.sttInfos.utterance}</p>
          <p className="player">
            <b style={{ width: '130px' }}>Verarbeitet:</b>
            <audio controls src={result.processedAudio} />
            <a
              href={result.processedAudio}
              download={`${baseName}.processed.wav`}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </p>
        </>
      )}
    </Box>
  )
}

export default Result
