import React, { useState } from 'react'
import { Chip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';

interface Props {
  files: File[]
  onUpdate: (files: File[]) => void
}

const FilePreview: React.FC<Props> = (props: Props) => {
  const { files, onUpdate } = props
  const [showAll, setShowAll] = useState(false)
  if (files.length < 1) {
    return null
  }

  const itemsToShow = showAll ? files : files.slice(0, 5)
  const moreItems = files.length - itemsToShow.length
  const hidableItems = itemsToShow.length - 5

  return (
    <>
      <h2>Ausgewählte Datei</h2>
      <div className="files">
        {itemsToShow.map((file) => (
          <Chip
            key={file.name}
            size="small"
            color="primary"
            label={file.name}
            onDelete={() => {
              onUpdate(files.filter((f) => f.name !== file.name))
            }}
            icon={<GraphicEqIcon />}
          />
        ))}
        {moreItems > 0 && (
          <Chip
            onClick={() => setShowAll(true)}
            size="small"
            label={`+ ${moreItems} weitere`}
          />
        )}
        {hidableItems > 0 && (
          <Chip
            onClick={() => setShowAll(false)}
            size="small"
            label={`Weniger anzeigen`}
          />
        )}
      </div>
      <Chip
        size="small"
        label="Alle löschen"
        onClick={() => onUpdate([])}
        color="secondary"
        icon={<DeleteIcon />}
      />
    </>
  )
}

export default FilePreview
