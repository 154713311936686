import React from 'react'
import './Footer.css'
import { ReactComponent as AristechLogo } from './images/aristech.svg'
import { ReactComponent as UniLogo } from './images/uniklinikum-heidelberg.svg'

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <a className="logo" href="https://aristech.de" target="_blank" rel="noreferrer">
        <AristechLogo />
      </a>
      <a className="logo uni" href="https://www.klinikum.uni-heidelberg.de/" target="_blank" rel="noreferrer">
        <UniLogo />
      </a>
    </footer>
  )
}

export default Footer
