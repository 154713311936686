import React, { useCallback } from 'react'
import { Slider, Box, Grid, Typography, Input } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const formatDuration = (duration: number) => {
  if (duration >= 1) {
    return `${duration.toFixed(3)} s`
  }
  return `${Math.round(duration * 1000)} ms`
}

interface Props {
  label: string
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  step?: number
  formatLabel?: (value: number) => string
  unitLabel?: string
  icon?: React.ReactNode
}

const ToolbarItem: React.FC<Props> = (props: Props) => {
  const {
    label,
    value,
    onChange,
    min,
    max,
    step,
    formatLabel = formatDuration,
    unitLabel = 's',
    icon = <AccessTimeIcon />,
  } = props

  const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value)
    if ((typeof min !== 'number' || newValue >= min) && (typeof max !== 'number' || newValue <= max)) {
      onChange(newValue)
    }
  }, [min, max, onChange])

  return (
    <Box sx={{ width: '100%', flexShrink: 0 }}>
      <Typography id="input-slider" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {icon}
        </Grid>
        <Grid item xs={6}>
          <Slider
            style={{ width: '100%' }}
            // marks
            size="small"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(event, newValue) => onChange(newValue as number)}
            valueLabelDisplay="auto"
            getAriaValueText={formatLabel}
            valueLabelFormat={formatLabel}
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            onChange={onInputChange}
            size="small"
            inputProps={{
              min,
              max,
              step,
              type: 'number',
              style: { textAlign: 'right' },
            }}
            endAdornment={unitLabel}
            style={{ width: '70px', color: 'white' }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ToolbarItem
